.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 1rem;
    align-content: baseline;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.wrapper {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 90%;
}
.wrapper header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}
.wrapper header div {
    display: grid;
    gap: 0.3rem;
}
.wrapper header form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.wrapper header form div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wrapper header form button {
    background-color: #0d3075;
    color: white;
    border: 1px solid transparent;
    padding: 0.5rem;
    border-radius: 10px;
}
.wrapper header form select,
.wrapper header form input {
    width: 6rem;
    height: 2rem;
    outline: none;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 10px;
}
.wrapper header form input {
    width: 7.5rem;
    text-transform: uppercase;
    font-family: inherit;
    text-indent: 0.2rem;
}
.wrapper header form div {
    display: flex;
    gap: 0.4rem;
    align-items: center;
}
.wrapper header h3 {
    /* padding: 0 0 5px; */
    font-size: 1.3rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.wrapper header p {
    font-family: inherit;
}
.earnings {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;
    align-items: center;
}
.earnings div {
    display: grid;
    gap: 0.5rem;
}
.earnings div h3 {
    display: flex;
    align-items: center;
    color: green;
    font-size: 2rem;
}
.earnings div p {
    font-size: 0.9rem;
    font-weight: 550;
    font-family: inherit;
}
.earnings div:nth-child(2) h3 {
    color: orange;
}
.earnings div:nth-child(2) p:nth-child(3) {
    border-bottom: 1px dashed blue;
    width: fit-content;
}
.class-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}
.classes {
    border: 1px solid #ccc;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.classes > h3 {
    font-size: 1rem;
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-weight: 500;
    font-family: inherit;
}
.classes h6 {
    font-size: 0.9rem;
    font-family: inherit;
    font-weight: 400;
    border: none;
}
.class-details {
    border-left: 3px solid orange;
    margin-left: 0.1rem;
    display: flex;
    align-items: center;
    align-content: baseline;
    padding: 0.3rem 0.2rem;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
}
.class-details h3 {
    color: #0d3075;
    font-size: 0.9rem;
    font-weight: bold;
}
.class-details p {
    font-size: 0.85rem;
    color: #0d3075;
}
.class-details p:first-child {
    border-bottom: 1px solid #0d3075;
}
.class-details:last-child {
    border-bottom: transparent;
}

@media screen and (max-width: 500px) {
    .Container {
        padding: 0.5rem;
    }
    .earnings div {
        width: 100%;
        padding: 0.5rem;
        box-shadow: 0px 0px 10px rgba(136, 136, 136, 0.1);
    }
}
