.Container {
    background-color: #eceef7;
    border-radius: 10px;
    min-height: 5rem;
    height: 100%;
    max-width: 1800px;
    & > h3 {
        padding: 2rem 2rem 0.5rem 2rem;
    }
}
.notification_wrapper {
    display: grid;
    gap: 0.5rem;
    margin-top: 1rem;
    padding: 2rem;
    padding-top: 0;
}
.notification {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    /* cursor: pointer; */
    padding: 0.5rem;
    border-left: 2px solid #1e87bd;
    background-color: white;
    &:hover {
        background-color: lightgray;
    }
    h3 {
        font-size: 0.9rem;
    }
    p {
        color: gray;
        font-size: 0.85rem;
    }
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
