.Container {
    display: grid;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    background-color: white;
    border: 1px solid #ccc;
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    font-weight: 500;
    padding: 1rem;
    .chat-wrapper {
        position: relative;
        .chart {
            width: 200px;
            height: 200px;
        }
        > p {
            position: absolute;
            top: 40%;
            left: 37%;
            font-size: 0.85rem;
        }
    }
    .details {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        div {
            border: 1px solid #ccc;
            padding: 0.5rem;
            display: grid;
            gap: 0.5rem;
            h3 {
                font-family: inherit;
                color: #777777;
                text-transform: uppercase;
                font-size: 10px;
            }
        }
        div:nth-child(even) {
            border-right: none;
        }
        div:nth-child(odd) {
            border-left: none;
        }
        div:nth-child(3),
        div:nth-child(4) {
            border-bottom: none;
        }
    }
}
