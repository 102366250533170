.modal {
    /* Default styles */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 500px;
    overflow: auto;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}
.thankyou {
    width: 700px;
    padding: 0;
    outline: none;
    @media screen and (max-width: 786px) {
        width: 90%;
    }
    // top: 40%;
}
.thankyou-header {
    outline: none;
    background-image: url("../../../../Assets/thankyou_bg.svg");
    background-position: center;
    background-size: cover;
    height: 250px;
    display: grid;
    justify-items: center;
    align-items: center;
    align-content: baseline;
    padding: 2rem;
    gap: 0.5rem;
    position: relative;
    button {
        position: absolute;
        right: 10px;
        top: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 1.5rem;
        cursor: pointer;
    }
    img {
        max-width: 150px;
        width: 90%;
    }
    h3 {
        color: #f71735;
        font-size: 30px;
    }
    p {
        font-weight: 550;
        font-size: 20px;
        text-align: center;
    }
}
.body {
    background: #fffaf4;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 1rem;
    gap: 2rem;
    h3 {
        font-size: 20px;
        text-align: center;
    }

    .Links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        a {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #d34fba;
            border: 1px solid #d34fba;
            padding: 0.5rem;
            font-size: 1rem;
            &:hover {
                color: white;
                background-color: #d34fba;
            }
        }
    }
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.form-wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    & > button {
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 1.5rem;
        border: none;
        background-color: inherit;
        cursor: pointer;
    }
    h2 {
        margin-bottom: 1rem;
    }
    form {
        display: grid;
        gap: 0.5rem;

        .form-control {
            position: relative;
            display: grid;
            gap: 0.4rem;
            label {
                font-family: "Open Sans" sans-serif;
                font-size: 1.1rem;
            }
            .date-input-container {
                position: relative;
                display: inline-block;
            }

            .date-input {
                padding-left: 25px; /* Adjust the padding as needed */
            }

            .date-input::-webkit-calendar-picker-indicator {
                position: absolute;
                left: 10px;
                // pointer-events: none; /* Prevent clicks on the icon */
            }

            input,
            select {
                height: 2rem;
                border: 1px solid lightgray;
                border-radius: 5px;
                width: 25rem;
                height: 3rem;
                outline: none;
                font-size: 1rem;
                user-select: none;

                text-indent: 0.6rem;
                background-color: rgba(253, 243, 231, 1);
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }
            .icon {
                position: absolute;
                color: rgba(250, 199, 89, 1);
                top: 15px;
                left: 10px;
            }
            .error {
                color: red;
            }
        }
        .input-number {
            position: absolute;
            width: 60px !important;
            top: 0;
            left: 30px;
            display: flex;
            height: 100%;
            select {
                width: 100%;
                color: black;
                font-size: 0.8rem;
                text-indent: 0.3rem;
                height: 90%;
                text-align: center;
                border: none;
                background-color: inherit;
            }
        }
    }
}
.buttons {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    margin-top: 0.5rem;
}
.submit {
    background-color: rgba(211, 65, 251, 1);
    color: white;
    text-align: center;
    padding: 1rem 0;
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
}
.close {
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 1rem 0;
}
@media screen and (max-width: 500px) {
    .form-wrapper {
        form {
            input,
            select {
                width: 100% !important;
            }
        }
    }
}
@media screen and (max-width: 786px) {
    .thankyou-header {
        height: unset;
        // padding: 0.5rem;
        justify-items: center;
        img {
            width: 100px;
        }
        h3 {
            font-size: large;
        }
        p {
            font-size: small;
        }
    }
    .body {
        h3 {
            font-size: large;
        }
        .Links a {
            font-size: small;
        }
    }
}
