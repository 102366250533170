.Container {
    background-color: #8950fc;
    color: white;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    font-family: "Poppins", sans-serif;
    border-radius: 10px;
    button {
        background-color: white;
        padding: 0.5rem;
        border-radius: 10px;
        color: blue;
        border: none;
        cursor: pointer;
    }
    div {
        display: grid;
        gap: 0.3rem;
        font-family: inherit;
        h3 {
            font-size: 18px;
            font-weight: 500;
        }
        p {
            color: #ccc;
            font-size: 15px;
        }
    }
}

/* Default styles */
.modal {
    // display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    // padding: 15px;
    width: 650px;

    overflow: auto;
    border-radius: 10px;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 90%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}

.form-wrapper {
    display: grid;
    gap: 2rem;
    align-content: baseline;
    justify-items: center;
    align-items: center;
    padding: 1rem;
    padding-top: 2rem;
    font-family: "Poppins", sans-serif;

    min-height: 400px;
    background: url("../../../../../Assets/Parent/form_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        text-align: center;
        h2 {
            font-family: inherit;
            color: black;
            font-size: 1.5rem;
        }
        p {
            font-family: inherit;
            color: #ada9a9;
            font-size: 1rem;
            letter-spacing: 0.1rem;
            span {
                font-weight: bold;
                color: black;
                border-bottom: 5px solid yellow;
            }
        }
    }
    form {
        display: grid;
        gap: 1rem;
        justify-items: center;
        align-self: center;
        width: 80%;
        .form-control {
            display: grid;
            gap: 0.3rem;
            width: 80%;
            position: relative;
            .icon {
                color: #1e87db;
                position: absolute;
                top: 7px;
            }
            span {
                color: red;
                font-size: 0.8rem;
            }
            // select {
            //     padding-left: 0.2rem;
            // }
            select,
            input {
                cursor: pointer;
                border: none;
                height: 2.2rem;
                border-bottom: 2px solid lightgray;
                color: black;
                text-indent: 2rem;
                font-family: inherit;
                font-size: 1rem;
                outline: none;
                appearance: none;
            }
        }
        button {
            background-color: #1e87db;
            color: white;
            text-align: center;
            border-radius: 10px;
            border: 1px solid;
            cursor: pointer;
            padding: 0 1rem;
            height: 2.5rem;
        }
    }
    .contact {
        display: grid;
        gap: 0.5rem;
        justify-items: center;
        P {
            font-family: inherit;
            font-size: 1rem;
            color: #ada9a9;
        }
    }
}
.form-wrapper > button {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 1.5rem;
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.close {
    width: fit-content;
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 0 1rem;
    height: 2.5rem;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@media screen and (max-width: 800px) {
    .form-wrapper {
        padding-top: 3rem;
    }
}
@media screen and (max-width: 500px) {
    .contact {
        p {
            text-align: center;
        }
    }
}
