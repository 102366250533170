$primary-color: #2986ce;

.Container {
    display: grid;
    gap: 1rem;
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    font-weight: 500;
    header {
        display: flex;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
        h3 {
            font-size: 18px;
            font-weight: inherit;
            font-family: inherit;
        }
        select {
            border: 1px solid $primary-color;
            color: $primary-color;
            padding: 0.5rem;
            border-radius: 5px;
            outline: none;
            cursor: pointer;
            font-size: 0.9rem;
            option {
                padding: 0.4rem;
            }
        }
    }

    .leaves-data {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        .apply_leave {
            max-width: 300px;
            border: 1px solid #dbdbdb;
            flex-grow: 1;
            height: fit-content;
            padding: 2rem;
            background-color: white;
            display: grid;
            gap: 0.5rem;
            button {
                background-color: $primary-color;
                border-radius: 5px;
                border: none;
                padding: 0.6rem 1rem;
                color: white;
                width: fit-content;
                cursor: pointer;
                outline: none;
            }
            p {
                text-decoration: underline;
                color: $primary-color;
                font-size: 0.8rem;
                cursor: pointer;
            }
        }
        .Applied_leaves {
            display: grid;
            gap: 1rem;
            flex-grow: 1;
            .leave {
                padding: 2rem;
                border: 1px solid #dbdbdb;
                border-radius: 10px;
                background-color: white;
                display: flex;
                gap: 1rem;
                .icon {
                    background-color: $primary-color;
                    width: 3rem;
                    height: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 1.2rem;
                    flex: 0 0 auto;
                }
                .leave-details {
                    flex-grow: 1;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 0.3fr;
                    row-gap: 1rem;
                    p {
                        font-size: 13px;
                        font-family: inherit;
                        span {
                            font-size: 13px;
                            color: #777777;
                        }
                    }
                    > div {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 0.5rem;
                        h4 {
                            text-transform: uppercase;
                            color: #777777;
                            font-weight: 400;
                            font-family: inherit;
                            font-size: 10px;
                        }
                        .menu button {
                            border: 0;
                            background: transparent;
                            cursor: pointer;
                        }
                        .menu button:hover,
                        .menu button:focus {
                            outline: 0;
                        }

                        .submenu {
                            list-style: none;
                            margin-bottom: 0;
                            display: none;
                            position: absolute;
                            padding: 0;
                            text-align: left;
                            background-color: white;
                            list-style-type: none;
                            padding: 0.5rem 0;
                            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12),
                                0 4px 4px 0 rgba(0, 0, 0, 0.24);
                        }
                        .submenu li {
                            text-decoration: none;
                        }
                        .submenu button {
                            padding: 0.5rem;
                            width: fit-content;
                            margin: 0;
                            width: 100%;
                            /* background-color: white; */
                        }
                        .submenu button:hover {
                            background-color: #ccc;
                        }
                        .menu button:focus + .submenu,
                        .submenu:hover {
                            display: block;
                        }
                    }
                }
            }
        }
        .pagination {
            justify-self: right;
            display: flex;
            gap: 0.3rem;
            align-items: center;
            justify-content: center;
            p {
                color: #9c8e8e;
                font-size: 0.9rem;
            }
            button {
                outline: none;
                cursor: pointer;
                border: none;
                background-color: inherit;
                margin-top: 0.3rem;
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width: 1136px) {
    .leaves-data {
        display: grid !important;

        .apply_leave {
            grid-column: 1/2;
            grid-row: 1/2 !important;
        }
    }
}

@media screen and (max-width: 800px) {
    .leaves-data {
        .Applied_leaves {
            .leave {
                .leave-details {
                    position: relative;
                    grid-template-columns: repeat(
                        auto-fit,
                        minmax(100px, 1fr)
                    ) !important;
                    gap: 1rem;
                    > p {
                        grid-column: 1/-1 !important;
                    }
                    .option {
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .Container {
        header {
            display: grid;
            gap: 1rem;
            width: 100%;
            justify-content: unset;
            select {
                justify-self: right;
            }
        }
        .icon {
            display: none !important;
        }
    }
}
