.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
