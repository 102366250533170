.Container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.left {
    flex: 1;
    border-radius: 10px;
    background-clip: border-box;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0);
    background-color: white;
    padding: 2rem;
    display: grid;
    gap: 0.5rem;
    font-family: "Poppins", sans-serif;
}
.right {
    width: 100%;
    max-width: 15rem;
    border-radius: 10px;
    background-clip: border-box;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0);
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
}
