.modal {
    /* Default styles */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 500px;
    overflow: auto;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.form-wrapper {
    display: grid;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    & > button {
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 1.5rem;
        border: none;
        background-color: inherit;
        cursor: pointer;
    }
    h2 {
        // margin-bottom: 1rem;
        text-align: center;
        font-size: 1.3rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #ccc;
    }
    form {
        display: grid;
        gap: 1rem;
        padding: 0.5rem;
        .form-control {
            position: relative;
            display: grid;
            gap: 0.5rem;
            width: 100%;
            label {
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
                font-size: 1.1rem;
            }
            input {
                border-radius: 5px;
                border: 1px solid #ccc;
                height: 3rem;
                width: 100%;
                outline: none;
                font-size: 20px;
                text-indent: 0.5rem;
                &::placeholder {
                    font-size: 14px;
                }
            }
            textarea {
                border: 1px solid #ccc;
                outline: none;
                resize: none;
                font-size: 1rem;
                height: 5rem;
                width: 100%;
                padding: 0.5rem;
                width: 90%;
                font-family: inherit;
                text-indent: 0.4rem;
            }

            .error {
                color: red;
            }
        }
    }
}
.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-self: center;
}
.submit {
    background-color: #1e87db;
    color: white;
    text-align: center;
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
    padding: 0 1rem;
    height: 2.5rem;
}
.close {
    width: fit-content;
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 0 1rem;
    height: 2.5rem;
}
.delete {
    width: fit-content;
    cursor: pointer;
    color: white;
    border: 1px solid transparent;
    background-color: red;
    border-radius: 5px;
    padding: 0 1rem;
    height: 2.5rem;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@media screen and (max-width: 700px) {
    .modal {
        width: 60%;
    }
}
@media screen and (max-width: 500px) {
    .form-wrapper {
        form {
            input,
            select {
                width: 100% !important;
            }
        }
    }
}
