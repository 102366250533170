.container {
    display: grid;
    gap: 2.5rem;
    // background: url("../../../Assets/Parent/mainBg.jpg");
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    align-content: baseline;
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-self: center;
    align-items: center;
    background-color: #eceef7;
    border-radius: 10px;
    min-height: 5rem;
    height: 100%;
    border-top-left-radius: 0;
    max-width: 1800px;
    width: 50%;
    margin: 5rem 0;
}

.wrapper > img {
    justify-self: center;
    align-self: center;
}
.left {
    width: 85%;
    align-items: center;
    justify-self: center;
    margin-top: 1rem;
}
.Success {
    background: url("../../../Assets/bgTransaction.png");
    height: 90%;
    width: 90%;
    justify-self: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    justify-items: center;
    align-content: center;
    align-items: center;
    gap: 1rem;
}
.Success img {
    width: 5rem;
    background-color: white;
}
.Success p:nth-child(2) {
    color: green;
    font-weight: bold;
    font-size: 1.1rem;
}
.Success p:nth-child(3) {
    color: gray;
    text-align: center;
    max-width: 90%;
}
.Success p:nth-child(4) {
    text-decoration: underline;
    color: gray;
}

@media screen and (max-width: 900px) {
    .left {
        width: 94%;
    }
}
