.Navbar-container {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 5;
    right: 0;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    transition: all 0.3s ease;
    animation: slide-down 0.3s;
    height: 60px;
    @media screen and (max-width: 450px) {
        padding: 1rem;
    }
    button {
        background-color: #ffc107;
        color: #333;
        padding: 13px 30px;
        font-weight: 600;
        text-transform: uppercase;
        border: none;
        font-family: "poppins" sans-serif;
        font-size: 13px;
        border-radius: 50px;
        cursor: pointer;
        @media screen and (max-width: 550px) {
            span {
                display: none;
            }
        }
    }
    .Left {
        // align-self: flex-end;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        img {
            width: 5rem;
            align-items: flex-start;
        }
        p {
            color: white;
            font-family: inherit;
            font-size: medium;
            text-transform: uppercase;
            span {
                font-weight: bold;
            }
        }
    }
}
.bg-nav {
    height: 40px;
    padding: 1rem 1.8rem;
    background: url("../../../../../Assets/Parent/mainBg.jpg");
    img {
        width: 2.5rem !important;
    }
}
@keyframes slide-down {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}
