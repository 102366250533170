.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 86vh;
    height: 100%;
    background-color: #ebf2fa;
}
.Tutor {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    align-content: baseline;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 95%;

    h3 {
        border-bottom: 1px solid #ddd;
        padding: 0 0 5px;
        margin: 0 0 15px;
        font-size: 1.3rem;
        display: block;
        font-weight: 500;
        line-height: 1.2;
        font-family: inherit;
        color: #212529;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-align: left;
    }
    .calender {
        min-height: 70vh;
        height: 100%;
    }
}
