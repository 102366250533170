.modal {
    // display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    // padding: 15px;
    width: 100%;
    max-width: 400px;
    height: 12rem;
    // height: 100%;
    // overflow: auto;
    border-radius: 5px;
    animation: bounce 0.5s ease;
    // animation-fill-mode: forwards;
    outline: none;
    // opacity: 0;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 90%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
.Container {
    display: grid;
    align-content: baseline;
}
.Container h3 {
    text-align: center;
    padding: 0.5rem 0;
    border-bottom: 1px dashed #ccc;
}
.Container > div {
    display: grid;
    gap: 0.7rem;
    align-content: baseline;
    align-self: baseline;
    padding: 1rem;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

.menuList {
    height: 10rem;
}
.error {
    color: red;
    font-weight: 550;
    font-size: 0.9rem;
}
.Container button {
    justify-self: center;
    background-color: #8950fc;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}
