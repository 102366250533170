/* In your CSS file or using styled-components, etc. */

.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    outline: none;
    width: 100%;
    max-width: 400px;
    animation: bounce 0.5s ease;
}

.custom-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-wrapper {
    display: grid;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: relative;
    header {
        height: 2rem;
        border-bottom: 1px solid #ccc;
        h2 {
            font-size: 1.1rem;
            text-align: center;
        }
        button {
            position: absolute;
            right: 0;
            outline: none;
            top: 0;
            cursor: pointer;
            border: none;
            font-size: 1.1rem;
            background-color: inherit;
        }
    }
    form {
        display: grid;
        gap: 1rem;
        .form-control {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 0.5rem;
        }
        select,
        textarea {
            width: 100%;
            height: 2.5rem;
            border: 1px solid #ccc;
            text-indent: 0.5rem;
            outline: none;
            border-radius: 5px;
        }
        textarea {
            height: 100%;
            resize: none;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
        }
        span {
            color: red;
        }
        .Action_btns {
            justify-self: center;
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            button {
                background-color: #d34fba;
                border: none;
                color: white;
                padding: 0.5rem;
                border-radius: 5px;
                cursor: pointer;
            }
            button[type="button"] {
                background-color: red;
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .form-control {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 0.5rem !important;
    }
    .modal {
        width: 60%;
    }
}
@media screen and (max-width: 500px) {
    .form-wrapper {
        form {
            input,
            select {
                width: 100% !important;
            }
        }
    }
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
