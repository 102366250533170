.Container {
    background-color: #fff;
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
    // justify-content: space-between;
    // padding-top: 1rem;
    font-family: "Poppins", sans-serif;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
    border-radius: 10px;
    gap: 1rem;
    .course {
        width: calc(33% - 1rem);
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        img {
            width: 100%;
            height: 15rem;
        }
        .Content {
            display: grid;
            align-content: baseline;
            gap: 0.1rem;
            height: 10rem;
            border-bottom: 1px solid #ccc;
            padding: 0.6rem;

            small {
                text-transform: uppercase;
                color: #999;
                font-weight: 600;
                font-family: inherit;
                font-size: 0.7rem;
            }
            h3,
            p {
                font-size: 1rem;
                color: #2f4354;
                font-family: inherit;
                font-weight: 550;
            }
            p:nth-child(3) {
                font-weight: 400;
                font-size: 0.85rem;
                // padding: 0.3rem 0 0 0;
                // text-align: justify;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                max-height: 6.8rem;
                text-overflow: ellipsis;
                // line-height: 1rem;
            }
            .Star-rating {
                display: flex;
                align-items: center;
                span {
                    color: #999999;
                    font-size: 10.5px;
                    font-weight: bold;
                    font-family: inherit;
                    font-weight: 500;
                }
            }
        }
        .explore {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            p {
                font-size: 0.9rem;
                color: #2f4354;
                font-family: inherit;
                font-weight: 400;
            }
            button {
                color: #552d91;
                border: 1px solid #662d91;
                border-radius: 10px;
                height: 2rem;
                font-family: inherit;
                background-color: white;
                cursor: pointer;
                font-size: 0.9rem;
                width: 6rem;
            }
        }
    }
}
@media screen and (max-width: 786px) {
    .Container .course {
        width: calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .Container .course {
        width: 100%;
    }
}
