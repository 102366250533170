.Container {
    border: 1px solid #ccc;
    width: 70px;
    height: 60px;
    display: grid;
    align-items: center;
    text-align: center;
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    gap: 0.1rem;
    border-radius: 4px;
    padding-bottom: 0.1rem;
    h3 {
        color: #222222;
        background-color: #ebeef2;
        font-size: 12px;
        font-family: inherit;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0.1rem;
    }
    p {
        font-family: inherit;
        font-size: 13px;

        &:nth-child(2) {
            font-size: 16px;
        }
        &:nth-child(3) {
            font-size: 12px;
            color: #777777;
        }
    }
}
.date-calender {
    display: grid;
    gap: 0.5rem;
    > p {
        color: #777777 !important;
        font-size: 0.7rem;
    }
}
