.Container {
    display: grid;
    gap: 1rem;
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    font-weight: 500;
    width: 100%;
    overflow: auto;
    h3 {
        font-size: 18px;
        font-weight: inherit;
        font-family: inherit;
    }
    table {
        border-collapse: collapse;
        table-layout: auto;

        min-width: 700px;
        text-align: left;
        border: 1px solid #ccc;
        thead {
            background-color: inherit;
            th {
                border: 1px solid #ccc;
                font-family: inherit;
                font-size: 11px;
                font-weight: 500;
                padding: 1rem 0.5rem;
                /* letter-spacing: 0.05rem; */
                text-transform: uppercase;
                vertical-align: middle;
            }
        }
        tbody {
            background-color: white;
            tr {
                cursor: pointer;
                color: #777;
                td {
                    border: 1px solid #ccc;
                    font-family: inherit;
                    font-size: 13px;
                    padding: 1rem 0.5rem;
                    color: black;
                    cursor: pointer;
                    text-transform: capitalize;
                    span {
                        color: #777777;
                        font-size: 12px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}
