.Container {
    background: url("../../../Assets/Parent/mainBg.jpg");

    background-size: cover;
    background-repeat: no-repeat;
    height: 80px;
    position: relative;
    z-index: 10;
    display: grid;
    padding-top: 0.5rem;
    header {
        justify-self: center;
        align-self: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 85%;
        .Left {
            // align-self: flex-end;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            img {
                width: 5rem;
                align-items: flex-start;
            }
            p {
                color: white;
                font-family: inherit;
                font-size: medium;
                text-transform: uppercase;
                span {
                    font-weight: bold;
                }
            }
        }
    }
    .user-btns {
        display: flex;
        gap: 0.5rem;
        justify-self: right;
    }
    .user-btn {
        border: none;
        font-size: 1.2rem;
        cursor: pointer;
        background-color: inherit;
        color: lightgray;
        display: none;
        margin-right: 0.3rem;
        width: fit-content;
    }
    .right_main {
        display: grid;
        justify-items: end;
        gap: 0.5rem;
        transition: all 0.3s ease;
        animation: slide-down 0.3s;
        .right {
            display: flex;
            align-items: center;
            gap: 1rem;
            .profile-menu {
                position: relative;
            }
            & > button,
            .profile-menu > button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                font-size: 1rem;
                line-height: 1.5;
                border-radius: 0.4rem;
                font-size: 1.2rem;
                height: 2.4rem;
                width: 2.4rem;
                outline: 0;
                cursor: pointer;
                color: white;
                vertical-align: middle;
                background-color: rgba(255, 255, 255, 0.2);
                transition: color 0.15s ease, background-color 0.15s ease,
                    border-color 0.15s ease, box-shadow 0.15s ease,
                    -webkit-box-shadow 0.15s ease;

                -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
                & :hover {
                    color: #8950fc;
                }
            }
            .logout {
                padding: 0 1rem;
                display: flex;
                gap: 0.3rem;
                align-items: center;
                width: fit-content;
                :hover {
                    color: #8950fc;
                }
            }
            .dropdown {
                float: left;
                overflow: hidden;
                padding: 10px;
                font-family: "Poppins", sans-serif;
                margin-right: 0.5rem;
            }
            .dropdown:hover .dropdown-content {
                display: block;
            }
            .dropbtn {
                font-family: inherit;
                font-size: 16px;
                padding-bottom: 5px;
                border: none;
                outline: none;
                color: white;
                background-color: inherit;
                font-family: inherit;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 0.2rem;
                color: #b5b5c3;
            }
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                button {
                    float: none;
                    color: black;
                    cursor: pointer;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                    border: none;
                    background-color: inherit;
                    text-align: left;
                    &:hover {
                        color: #8950fc;
                    }
                }
            }
            .notifications {
                display: flex;
                align-items: center;
                position: relative;
                button {
                    margin-top: 0.5rem;
                    border: none;
                    font-size: 1.7rem;
                    background-color: inherit;
                }
                p {
                    position: absolute;
                    top: 4px;
                    right: -2px;
                    color: white;
                    background-color: #9e3030;
                    height: 1.2rem;
                    width: 1.2rem;
                    border-radius: 50%;
                    font-size: 0.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    animation: slide-down 0.2s;
    padding-top: 0;
}
.logout-popup {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    font-size: 1rem;
    text-align: center;
}
.popup-actions {
    display: flex;
    gap: 0.3rem;
    justify-self: right;
    justify-content: flex-end;
    margin: 0.4rem 0.5rem 0.1rem 0.5rem;
}
@keyframes slide-down {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 676px) {
    .Container {
        align-items: flex-start;
        padding: 0.5rem 0;
        height: 70px;
        transition: all 0.3s ease;
        header {
            display: grid;
            grid-template-columns: 40% 1fr;
            align-content: baseline;

            .Left {
                align-self: baseline !important;
                img {
                    width: 2rem;
                }
                p {
                    font-size: small;
                }
            }

            .user-btn {
                display: block;
                justify-self: right;
            }
            .right_main {
                display: none;
                align-items: flex-start;
                justify-self: right;
                grid-column: 1/-1;
                transition: all 0.5s ease;
                & > button {
                    display: block;
                }
            }

            .unhide {
                display: grid;
            }
        }
    }
    .main-unhide {
        height: 120px;
    }
}

@media screen and (max-width: 430px) {
    .Container {
        header {
            align-items: center;
        }
    }
}
