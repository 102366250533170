.event-container {
    background-color: #333;
    border-radius: 4px 4px 0 0;
    color: white;
    display: grid;
    gap: 0.5rem;
}
.event-container h3 {
    border-bottom: 1px solid #ebebeb;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 14px;
}
.content {
    padding: 0 10px;
    display: grid;
    gap: 0.3rem;
}
.content p {
    font-weight: bolder;
    font-size: 12px;
}
.content p span {
    font-size: 11px;
    font-weight: 400;
}
.actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 5px 5px 5px;
}
.event-container button {
    border: none;
    background-color: inherit;
    color: #d838ff;
    font-size: 12px;
    cursor: pointer;
}
.contain {
    padding: 1rem 1rem 4rem 1rem;
    background-color: #ebf2fa;
}
.calender-wrapper {
    display: grid;
    grid-template-columns: 78% 1fr;
    gap: 1rem;
    max-width: 81vw;
}
.calender {
    background-color: white;
    width: 100%;
    font-family: inherit;
}
.search {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    padding: 1rem;
}
.form-control {
    display: grid;
    gap: 0.5rem;
}
.form-control input,
.form-control select,
.select {
    height: 2.5rem;
    border: 1px solid #ced4da;
    outline: none;
    font-size: 1.1rem;
    text-indent: 1rem;
    width: 100%;
}

@media screen and (max-width: 1140px) {
    .calender-wrapper {
        grid-template-columns: 70% 1fr;
    }
}
@media screen and (max-width: 900px) {
    .calender-wrapper {
        grid-template-columns: 1fr;
    }
    .search {
        grid-column: 1/-1;
        grid-row: 1/2;
    }
}
@media screen and (max-width: 800px) {
    .calender {
        width: 100%;
        max-width: 600px;
        overflow: hidden;
    }
}
