.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: flex-end;
    z-index: 9;
    transition: 100ms;
}
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    opacity: 1;
    /* max-width: 700px; */
    overflow: scroll;
    min-width: 500px;
    width: fit-content;
    background: #ffffff;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    position: relative;
}
.modal-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.modal-content-header {
    padding: 1rem 1.5rem;
    font-weight: 500;
    background-color: #4a5363;
    display: flex;
    top: 0;
    position: sticky;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    h3 {
        font-family: "Noto Sans NKo Unjoined", sans-serif;
        font-weight: 500;
        color: white;
        font-size: 1.1rem;
    }
    button {
        width: fit-content;
        border: none;
        cursor: pointer;
        background-color: inherit;
    }
}

.Container {
    border: 1px solid #ccc;

    padding: 1rem;
    margin: 1rem 0.5rem;
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    font-weight: 500;
    display: grid;
    gap: 0.8rem;

    .leave-dates {
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        align-items: center;
        p {
            color: #222222;
            font-family: inherit;
            font-size: 13px;
            span {
                color: #777777;
            }
        }
    }
    header {
        display: flex;
        gap: 0.6rem;
        align-items: center;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #ccc;
    }
}

.profile {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #86c06a;
    display: grid;
    place-content: center;
    p {
        color: white;
        font-family: sans-serif;
        font-size: small;
    }
}
.comment-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.5rem;
    > p {
        color: #777777;
        font-size: 13px;
    }
}
.employee-details,
.Comment-details {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    h5 {
        font-size: 13px;
        color: #222222;
        font-family: inherit;
        text-transform: capitalize;
        font-weight: 450;
    }
    p {
        font-family: inherit;
        color: #777777;
        font-weight: 500;
        font-size: 12px;
    }
}
.Comments {
    // margin-top: 1rem;
    display: grid;
    gap: 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
}
.add-comment {
    width: 100%;
    display: flex;
    input {
        width: 100%;
        height: 3rem;
        border: none;
        outline: none;
        font-size: 0.9rem;
    }
    button {
        background-color: #ccc;
        border: none;
        cursor: pointer;
        width: 3rem;
    }
}
@media screen and (max-width: 767px) {
    .modal-content {
        width: 80%;
        min-width: 80%;
    }
    .modal-content-header p {
        font-size: 0.8rem;
    }
}
@keyframes run {
    0% {
        right: -10rem;
    }
    100% {
        right: 0rem;
    }
}
