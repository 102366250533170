.Container {
    display: grid;
    gap: 1rem;
}
.Edit-timezone {
    border: none;
    background-color: inherit;
    margin-top: 0.1rem;
    font-size: 0.8rem;
    cursor: pointer;
}
.Loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* bottom: -70px; */
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 10;
}
.loader {
    z-index: 10;
    overflow: hidden !important;
}
.refresh-btn {
    // background-color: inherit;
    // border: none;
    cursor: pointer;
}
.Class-container {
    font-family: "Poppins", sans-serif;
    display: grid;
    grid-template-columns: 1fr 330px;
    gap: 1rem;
    align-content: flex-start;

    .Class-left {
        border-radius: 10px;
        background-clip: border-box;
        box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0);
        background-color: white;
        padding: 1rem;
        display: grid;
        gap: 0.5rem;
        align-content: baseline;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        flex: 1;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        header {
            display: flex;
            justify-content: space-between;

            div {
                display: grid;
                gap: 0.3rem;
                align-content: baseline;

                h3 {
                    font-size: 1.1rem;
                    font-family: inherit;
                    font-weight: 540;
                }
                p {
                    color: #9797ae;
                    font-size: 13px;
                    font-family: inherit;
                    span {
                        color: #8950fc;
                    }
                }
            }
        }
        .Classes {
            display: grid;
            gap: 0.5rem;
            min-width: 300px;
            .Class {
                display: grid;
                // grid-template-columns: repeat(3, 1fr);
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
                // align-items: baseline;
                justify-items: center;
                gap: 1rem;
                .time {
                    display: grid;
                    align-items: center;
                    align-self: baseline;
                    justify-items: center;
                }
                .time p:last-child {
                    padding: 0.4rem;
                    // color: white;
                    width: fit-content;
                    text-align: center;
                }
                .cancelled {
                    align-self: baseline;
                    color: white;
                    padding: 0.5rem;
                    border-radius: 10px;
                    background-color: rgb(176, 80, 80);
                    width: fit-content;
                }
                .topic {
                    display: flex;
                    gap: 0.5rem;
                    // align-items: center;
                    justify-self: left;

                    img {
                        width: 2.5rem;
                        height: 2.5rem;
                        border-radius: 50%;
                    }
                }
                h3 {
                    color: #3f4354;
                    font-family: inherit;
                    font-size: 14.5px;
                    word-wrap: break-word;
                    font-weight: 550;
                    text-transform: capitalize;
                }
                p {
                    color: #9d9da3;
                    font-size: 12.5px;
                }
                .class-btns-Approve {
                    display: grid;
                    // p {
                    //     text-align: center;
                    // }
                    gap: 0.3rem;
                    align-self: baseline;
                    justify-items: center;
                    .btns {
                        display: flex;
                        gap: 1rem;
                        flex-wrap: wrap;
                    }
                }
                .class-btns {
                    display: flex;
                    gap: 0.3rem;
                    align-items: center;
                    // justify-items: center;
                    align-content: baseline;
                    margin-top: 0.5rem;
                    button {
                        color: white;
                        border: none;
                        height: fit-content;
                        padding: 0.6rem;
                        border-radius: 5px;
                        cursor: pointer;
                        background-color: #8950fc;
                        outline: none;
                    }
                    .work-book {
                        background-color: #ffa800;
                    }
                    .Action-btns {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        button:disabled {
                            cursor: not-allowed;
                            background-color: gray;
                        }
                    }

                    .Action-btns:nth-child(2) {
                        button:first-child {
                            color: #8950fc;
                            background-color: inherit;
                            text-decoration: underline;
                            padding: 0rem;
                        }
                        button:nth-child(2) {
                            padding: 0;
                            border: 1px solid #8950fc;
                            background-color: white !important;
                            color: #8950fc;
                        }
                    }
                }
            }
        }
    }
    .Class-right {
        display: grid;
        gap: 1rem;
        align-content: baseline;
        .news {
            background: url("../../../../../Assets/Parent/course-bg.svg");
            box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
            width: 100%;
            max-width: 300px;
            background-repeat: no-repeat;
            align-self: baseline;
            background-size: cover;
            border-radius: 0.4rem;
            padding: 3rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-position: right;

            background-color: #8950fc;
            color: white;
            h3 {
                font-family: inherit;
                font-size: 18px;
            }
            p {
                font-size: 13px;
                font-family: inherit;
                // font-weight: bold;
                word-break: break-all;
            }
        }
        .support {
            background: url("../../../../../Assets/helpsupport.jpg");
            box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
            width: 100%;
            background-repeat: no-repeat;
            align-self: baseline;
            background-size: cover;
            border-radius: 0.4rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            height: 100%;
            min-height: 15rem;
            align-items: center;
            width: 95%;
            // padding: 1rem;
            // justify-content: center;
            background-position: center;
            color: white;
            h3 {
                color: #fff;
                margin-top: 0.5rem;
                text-align: center;
                font-family: "Quicksand";
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.374px;
            }
            button {
                border-radius: 3.524px;
                background: #6398f8;
                border: transparent;
                font-size: 16px;
                padding: 0.5rem;
                cursor: pointer;
                color: white;
            }
        }
    }
}
.approve-btn {
    color: white !important;
    background-color: green;
    padding: 0.5rem;
    border: transparent;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: green !important;
    }
}
.reject {
    padding: 0.5rem;
    border: transparent;
    border-radius: 10px;
    cursor: pointer;
    color: white !important;
    background-color: red;
}
@media screen and (max-width: 1316px) {
    .Class-container {
        grid-template-columns: 1fr;
        .Class-right {
            width: unset;
            max-width: unset;
        }
    }
}

@media screen and (max-width: 760px) {
    .Class-container {
        .Class-left {
            .Classes {
                .Class {
                    grid-template-columns: 50% 1fr;
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .Class-container {
        padding: 0.4rem;
        .Class-left {
            .Classes {
                min-width: 100%;
                .Class {
                    display: grid;
                    gap: 0.4rem;
                    align-items: center;
                    // grid-template-columns: 60% 1fr;

                    // align-items: baseline;
                }

                .class-btns {
                    grid-row: 1/2;
                    grid-column: 2/3;
                    .Action-btns {
                        button {
                            font-size: small;
                            padding: 0.3rem;
                        }
                    }
                }
            }
            header {
                div {
                    p {
                        display: none !important;
                    }
                }
            }
        }
    }
}
