.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: flex-end;
    z-index: 9;
    transition: 100ms;
}
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    opacity: 1;
    /* max-width: 700px; */
    overflow: scroll;
    min-width: 400px;
    width: fit-content;
    background: #ffffff;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    position: relative;
    form {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        justify-items: baseline;
        gap: 1rem;
        padding: 1rem;
    }
}
.modal-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.modal-content-header {
    padding: 1rem 1.5rem;
    font-weight: 500;
    background-color: #f9fafb;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    h3 {
        font-family: "Noto Sans NKo Unjoined", sans-serif;
        font-weight: 500;
    }
    button {
        width: fit-content;
        border: none;
        cursor: pointer;
        background-color: inherit;
    }
}
.form-contrl-date {
    display: flex;
    justify-content: space-between;

    border-radius: 5px;
    border: 1px solid #ccc;
    align-items: center;
    p {
        padding: 0.5rem;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #ccc;
        width: fit-content;
    }
    div {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        padding: 1rem;
        transition: all 0.5s ease-in-out;
        &:focus-within {
            background-color: #dce8f7;
        }
        label {
            font-family: "Noto Sans NKo Unjoined", sans-serif;
            font-weight: 500;
            font-size: 0.7rem;
            color: #777777;
            text-transform: uppercase;
        }
        input {
            font-family: "Noto Sans NKo Unjoined", sans-serif;
            font-weight: 500;
            background-color: inherit;
            border: none;
            text-transform: uppercase;
            outline: none;
            width: 7rem;
            user-select: none;
        }
    }
}
.form-control {
    display: grid;
    gap: 0.5rem;
    label {
        font-family: "Noto Sans NKo Unjoined", sans-serif;
        font-weight: 500;
        font-size: 0.95rem;
    }
    select {
        border: 1px solid #ccc;
        height: 2.5rem;
        width: 100%;
        outline: none;
        border-radius: 6px;
        cursor: pointer;
        text-indent: 0.5rem;
    }
    textarea {
        outline: none;
        font-family: "Noto Sans NKo Unjoined", sans-serif;
        font-weight: 500;
        border-radius: 6px;
        border: 1px solid #ccc;
        padding: 0.7rem 0rem;
        text-indent: 0.5rem;
        resize: none;
        width: 100%;
    }
}
.submit {
    padding: 10px;
    display: flex;
    flex: 1;
    right: 20px;
    gap: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 5px 2px 10px 1px #d8dde6;
    justify-content: flex-end;
    button {
        padding: 0.5rem 1rem;
        border-radius: 10px;
    }
    button:first-child {
        border: 1px solid #ccc;
        cursor: pointer;
        background-color: #ffffff;
    }
    button:nth-child(2) {
        background-color: #2986ce;
        color: white;
        cursor: pointer;
        border: 1px solid #2986ce;
    }
}
.Day-types {
    display: grid;
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    gap: 0.5rem;
    .daytype {
        display: flex;
        font-family: inherit;
        gap: 0.4rem;
        background-color: #e4e5e9;
        width: fit-content;
        border-radius: 5px;
        padding: 0.3rem;
    }
    p {
        font-size: 0.8rem;
    }
    button {
        color: black;
        font-family: inherit;
        border: 1px solid transparent;
        padding: 0.3rem;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;
        font-size: 0.7rem;
    }
    .active {
        background-color: #ffffff;
    }
}
.dayType_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0.5em;
    p {
        margin-top: 1rem;
    }
    .daytype_formControl {
        display: grid;
        gap: 0.5rem;
        flex-grow: 1;
        label {
            font-family: inherit;
            font-size: 0.7rem;
        }
        select {
            width: 100%;
            height: 2rem;
            border: 1px solid #ccc;
        }
    }
}
@media screen and (max-width: 767px) {
    .modal-content {
        width: 80%;
        min-width: 80%;
    }
    .modal-content-header p {
        font-size: 0.8rem;
    }
}
@keyframes run {
    0% {
        right: -10rem;
    }
    100% {
        right: 0rem;
    }
}

@media screen and (max-width: 500px) {
    .form-contrl-date {
        // flex-wrap: wrap;
        p {
            font-size: smaller;
            padding: 0.2rem;
            display: none;
        }
        div {
            padding: 0.3rem;
            input {
                font-size: smaller;
                width: fit-content;
            }
        }
    }
}
@media screen and (max-width: 360px) {
    .form-contrl-date {
        // flex-wrap: wrap;
        p {
            display: none;
        }
    }
}
