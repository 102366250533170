.Container {
    border-radius: 10px;
    background-clip: border-box;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0);
    background-color: white;
    padding: 2rem;
    display: grid;
    gap: 1rem;
    font-family: "Poppins", sans-serif;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: scroll;
    header {
        display: grid;
        gap: 0.5rem;
    }
    h3 {
        font-size: 1.1rem;
        font-family: inherit;
        font-weight: 540;
    }
    p {
        color: #9797ae;
        font-size: 13px;
        font-family: inherit;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    .table {
        min-width: 800px;
        table {
            overflow: scroll;
            border-collapse: collapse;
            width: 100%;
            text-align: left;
            thead {
                tr {
                    border-bottom: 1px solid #ebedf3;
                    border-top: 1px solid #ebedf3;

                    background-color: #f3f6f9;
                    th {
                        padding: 1rem;
                        text-transform: uppercase;
                        font-family: inherit;
                        font-size: 14px;
                        font-weight: 500;
                        color: #3f4254;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 1rem;
                        color: #3f4254;
                        font-size: 15px;
                        font-family: inherit;
                        font-weight: 550;
                        vertical-align: top;
                        word-wrap: break-word;
                        .video-icon {
                            display: grid;
                            align-items: center;
                            justify-items: center;
                            width: fit-content;
                            cursor: pointer;
                            padding: 1rem;
                            background-color: #eee5ff;
                        }
                        .link-upload {
                            background-color: #8950fc;
                            color: white;
                            border: none;
                            padding: 0.5rem;
                            border-radius: 5px;
                        }
                        span {
                            color: #9797ae;
                            font-size: 0.8rem;
                        }
                        .progress {
                            display: flex;
                            justify-content: space-between;
                            button {
                                color: #1bc5bd;
                                font-size: 0.9rem;
                                text-decoration: underline;
                                background-color: inherit;
                                border: none;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
