.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    align-items: center;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;

    .wrapper,
    .profile-wrapper {
        display: grid;
        align-items: center;
        justify-self: center;
        background-color: #fff;
        width: 100%;
    }
    .wrapper > button {
        width: fit-content;
        margin: 1rem;
        padding: 0.5rem;
        color: #0077b6;
        border: 1px solid #0077b6;
        background-color: inherit;
        cursor: pointer;
    }
    form {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 1rem;
        padding: 2rem;
        .bank-details {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 0.7rem 1.5rem;
            grid-column: 1/-1;
            border: 1px solid #ccc;
            padding: 0.5rem;
        }
        .bank-details > h3 {
            padding: 0.5rem;
            border-bottom: 1px solid #ccc;
            font-size: 1.1rem;
            display: block;
            font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
                helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
                segoe ui emoji, segoe ui symbol, noto color emoji;
            font-weight: 500;
            color: #0077b6;
            grid-column: 1/-1;
        }
        .profile-handler {
            display: grid;
            justify-items: center;
            grid-column: 1/-1;
            .profile-img {
                img {
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
        .full-row {
            grid-column: 1/-1;
        }
        .form-control-multi-select {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            font-family: "Montserrat", sans-serif;

            label {
                font-family: inherit;
                font-size: 0.9rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                text-align: left;
            }
            .important {
                color: red;
            }
        }
        .form-control {
            display: grid;
            gap: 0.3rem;
            font-family: "Montserrat", sans-serif;
            span {
                color: red;
            }
            label {
                font-family: inherit;
                font-size: 0.9rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                text-align: left;
            }
            input,
            textarea,
            select {
                display: block;
                width: 100%;
                text-indent: 1rem;
                height: 2.6rem;

                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                outline: none;
                font-size: 1rem;
                transition: all 0.3s ease-in-out;
                resize: none;
            }
            input:disabled {
                background-color: lightgray;
            }
            textarea {
                height: 100%;
            }
            .select-code {
                position: relative;
                .select-btn {
                    position: absolute;
                    top: 0;
                    height: 100%;
                }
            }
        }
        & > button {
            background-color: #fff;
            border: 1px solid #0077b6;
            border-radius: 0.25rem;
            color: #212529;
            color: #0077b6;
            cursor: pointer;
            display: inline-block;
            font-size: 0.875rem;
            font-weight: 400;
            grid-column: 1/-1;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            justify-self: center;
            line-height: 1.5;
            margin-top: 1rem;
            padding: 0.375rem 0.75rem;
            text-align: center;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            -webkit-user-select: none;
            user-select: none;
            vertical-align: middle;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
        }
    }
}
.skill-Wrapper {
    grid-column: 1/-1;
    display: grid;
    gap: 1rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin-top: 1rem;
    input {
        height: 1.5rem !important;
    }
}
.react-multi-select {
    width: 100%;
}
.skill-Wrapper header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
}
.skill-Wrapper header button {
    color: white;
    border: none;
    /* background-color: inherit; */
    background-color: #0077b6;
    padding: 0.2rem;
    cursor: pointer;
    font-weight: bolder;
}
.skill-Wrapper > button,
.qualification button {
    margin-top: 0.5rem;
    background-color: green;
    color: white;
    border: none;
    padding: 0.4rem;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
}
.skills {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0;
}
.form-control-select,
.last div {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.form-control-select div {
    display: grid;
    gap: 0.5rem;
    flex-grow: 2;
}
.skills button {
    grid-column: unset;
    align-self: self-end;
}
.qualification {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    gap: 0.5rem;
}
.qualification .form-control:last-child,
.skills .form-control:last-child {
    position: relative;
}
.qualification .form-control:last-child button,
.skills .form-control:last-child button {
    background-color: red;
    padding: 0;
    font-size: 1rem;
    height: 2.3rem;
    border: none;
    color: white;
    right: 0;
    width: fit-content;
    padding: 0 1rem;
    top: 0;
}
.skills .form-control:last-child button {
    right: 10px;
}
.qualification input {
    width: unset;
    padding: 0.5rem 0;
    text-indent: 0.3rem;
}

.profile-wrapper {
    gap: 1rem;
    padding: 1rem;
}

.profile-wrapper > img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
}
.profile-wrapper header h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-weight: 500;
    color: #0077b6;
    text-transform: capitalize;
}
.profile-wrapper header h3 span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: SandyBrown;
}
