.Container {
    border-radius: 10px;
    background-clip: border-box;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0);
    background-color: white;
    padding: 2rem;
    display: grid;
    gap: 1rem;
    font-family: "Poppins", sans-serif;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: scroll;
    @media screen and (max-width: 450px) {
        padding: 1rem;
    }
    header {
        display: grid;
        gap: 0.5rem;
    }
    h3 {
        font-size: 1.1rem;
        font-family: inherit;
        font-weight: 540;
    }
    p {
        color: #9797ae;
        font-size: 13px;
        font-family: inherit;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    .myClasses {
        // min-width: 600px;
        table {
            overflow: scroll;
            border-collapse: collapse;
            width: 100%;
            text-align: left;
            thead {
                tr {
                    border-bottom: 1px solid #ebedf3;
                    border-top: 1px solid #ebedf3;
                    padding: 1rem 0;
                    th {
                        padding: 0.5rem 0;
                        text-transform: uppercase;
                        font-family: inherit;
                        font-size: 14px;
                        color: #3f4254;
                    }
                    th:nth-child(2) {
                        color: #6993ff;
                    }
                }
            }
            tbody {
                tr {
                    .preferredDays {
                        word-wrap: break-word;
                    }
                    td {
                        padding: 1rem 1rem 1rem 0;
                        color: #3f4254;
                        font-size: 15px;
                        font-family: inherit;
                        font-weight: 550;
                        vertical-align: middle;
                        word-wrap: break-word;
                        @media screen and (max-width: 600px) {
                            font-size: small;
                        }
                        span {
                            color: #9797ae;
                            font-size: 0.8rem;
                        }
                        .progress {
                            display: flex;
                            justify-content: space-between;
                            button {
                                color: #1bc5bd;
                                font-size: 0.9rem;
                                text-decoration: underline;
                                background-color: inherit;
                                border: none;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .preferredDays {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .courseName small {
        display: none;
    }
}
