.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    align-items: center;
    min-height: 87.3vh;
    height: 100%;
    background-color: #ebf2fa;
}
.wrapper {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    justify-self: center;
    background-color: #fff;
    width: 98%;
    & > h3 {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 20px;
        font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
            helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
            segoe ui emoji, segoe ui symbol, noto color emoji;
        font-weight: 500;
        color: #0077b6;
        text-transform: capitalize;
        span {
            height: 16px;
            border-radius: 50%;
            display: inline-block;
            background-color: SandyBrown;
            width: 16px;
        }
    }
}
.profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.initials {
    border: 1px solid #ddd;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0.5rem; */
}
.initials img {
    width: 100%;
    height: 100%;
}
.initials h4 {
    padding: 5px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 2.9rem;
    font-weight: 400;
    text-align: center;

    text-transform: uppercase;
}
.Contact {
    padding: 0.5rem;
    display: flex;
    gap: 1.4rem;
    border-right: 1px solid #ddd;
}
.card-data h4 {
    margin-bottom: 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
}
.card-data {
    display: grid;
    align-items: start;
    align-content: start;
}
.card-data p:nth-child(2) {
    color: #016997;
    margin-bottom: 0.2rem;
}
.card-data p:nth-child(2) span {
    margin-bottom: 0.2rem;
}
.card-data p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
.action-btn {
    width: 100px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    justify-content: center;
    margin-top: 0.4rem;
    cursor: pointer;
    padding: 0.3rem 0;
    color: #fff;
    background: #186bae !important;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid transparent;
}
.internal {
    padding: 0.5rem;
    display: grid;
    align-content: start;
    gap: 0.3rem;
}
.internal > h3 {
    font-weight: 700;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    margin-bottom: 1rem;
}
.internal div {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
}
.internal div h3,
.internal p {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.profile-data {
    margin-top: 1rem;
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    align-items: baseline;
    gap: 1rem;
}
.data-wrap,
.data-wrap-courses,
.data-wrap-bank {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    border-radius: 2px;
    border: 1px solid #ddd;
    margin: 5px 0 10px;
    background: #fff;
}
.data-wrap h3,
.data-wrap p,
.data-wrap-courses h3,
.data-wrap-bank h3,
.data-wrap-bank h4 {
    padding: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.data-wrap-bank div {
    display: grid;
    grid-template-columns: 20% 10% 1fr;
    padding: 0 0.5rem;
}
.data-wrap-bank h4 {
    border-bottom: none;
    /* font-size: 0.9rem; */
    font-weight: 500;
    padding: 0.2rem;
}
.data-wrap p {
    font-weight: 400;
    font-size: 0.8;
}
.data-wrap-courses {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.data-wrap-courses h3 {
    border: none;
}
.data-wrap-courses ul {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 0.5rem;
}
.data-wrap-courses li {
    text-decoration: underline;
    cursor: pointer;
}
.data-wrap-courses li::after {
    content: ", ";
}

.data-wrap-courses li:last-child::after {
    content: ""; /* Remove the comma from the last item */
}
.data-wrap span {
    padding: 0.5rem;
}
.files {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.Documents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: baseline;
}
.skills table {
    width: 100%;
    border-collapse: collapse;
}
.skills table th {
    padding: 0.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}
.skills table tbody tr {
    border: none;

    border-bottom: 1px solid #ddd;
}
.skills table tbody tr:last-child {
    border-bottom: none;
}
.skills tbody tr td {
    padding: 0.5rem;
}
.skills tbody tr td:last-child span {
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #016997 !important;
    width: fit-content;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}
.qualification {
    display: grid;
    gap: 0.5rem;
}
.qualification ul {
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    text-decoration: none;
    list-style-type: none;
    align-items: center;
    border-bottom: 1px solid #676a6c;
}
.qualification ul:last-child,
.InstitutionsAttended li:last-child {
    border-bottom: none;
}
.InstitutionsAttended {
    display: grid;
    gap: 0.4rem;
    margin: 0.5rem 2rem;
}
.InstitutionsAttended li {
    color: #676a6c;
    border-bottom: 1px solid #676a6c;
}

.qualification li {
    color: #676a6c;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}
.qualification li:last-child {
    font-weight: bold;
}
.qualification li span {
    margin: 0 0.3rem 0.1rem 0;
    width: 0.3rem;
    display: inline-block;
    padding: 0;
    height: 0.3rem;
    background-color: #676a6c;
    border-radius: 50%;
}
.updateddocuments {
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
}
.updateddocuments img {
    /* width: 100px; */
    height: 100%;
    max-height: 80px;
    cursor: pointer;
}
.Skeleton-Container {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    padding: 1rem;
}
.skeleton-left {
    border-right: 1px solid lightgray;
    padding: 0 1rem;
}
.skeleton-rightside {
    padding-left: 1rem;
}
.content {
    padding: 1rem;
    border: 1px solid #ddd;
}
@media screen and (max-width: 767px) {
    .Skeleton-Container {
        grid-template-columns: 1fr;
        padding: 0;
    }
    .skeleton-left {
        border: none;
    }
    .skeleton-rightside {
        display: none;
    }
    .profile-data,
    .Documents {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 450px) {
    .data-wrap h3,
    .data-wrap p,
    .skills table th {
        font-size: 4vw;
    }
    .updateddocuments img {
        max-height: 50px;
    }
}

@media screen and (max-width: 900px) {
    .profile {
        grid-template-columns: 1fr;
    }
    .Contact {
        border: none;
    }
    .internal div {
        width: 90%;
    }
}
@media screen and (max-width: 500px) {
    .Contact {
        display: grid;
    }
}
