.Container {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffefdb; /* Semi-transparent black background */
    z-index: 999; /* Adjust the z-index to ensure it appears above other content */
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 200px;
        user-select: none;
        animation: rotate 3s linear infinite;
    }
}
@keyframes rotate {
    0% {
        transform: rotateY(0deg) rotateX(0deg); /* Initial rotation angles */
    }
    50% {
        transform: rotateY(360deg) rotateX(0deg); /* Rotate along Y-axis */
    }
    100% {
        transform: rotateY(720deg) rotateX(360deg); /* Rotate along X-axis and back */
    }
}
