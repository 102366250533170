.container {
    display: flex;
    gap: 0.1rem;
    button {
        display: flex;
        align-items: center;

        justify-content: center;
        border: none;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 5px;
        font-size: 0.9rem;
        font-family: "Poppins", sans-serif;
        padding: 0.8rem 2rem;
        outline: 0;
        cursor: pointer;
        border-bottom-right-radius: 0;
        font-weight: 500;
        text-transform: uppercase;
        color: white;
        vertical-align: middle;
        background-color: rgba(255, 255, 255, 0.2);
        transition: color 0.15s ease, background-color 0.15s ease,
            border-color 0.15s ease, box-shadow 0.15s ease,
            -webkit-box-shadow 0.15s ease;

        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        & :hover {
            color: #8950fc;
        }
        .icon {
            display: none;
        }
    }
    .Active-btn {
        background-color: white;
        color: black;
        font-weight: 550;
    }
}

@media screen and (max-width: 676px) {
    .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 10;
    }
    .container {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.75);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        transition: width 0.5 ease;
        botton {
            display: none;
        }
    }
    .showTabs {
        width: 55%;
        max-width: 300px;

        .Active-btn {
            background-color: rgb(243, 241, 241);
        }
        button {
            justify-content: space-between;
            color: black;
            font-size: small;
            .icon {
                display: block;
                font-size: 1.1rem;
            }
        }
    }
}
