.Container {
    padding: 1rem;
    background-color: white;
    display: grid;
    gap: 1rem;
    width: 100%;
    max-width: 350px;
    header {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}
