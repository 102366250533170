.Container {
    font-family: "Noto Sans NKo Unjoined", sans-serif;
    font-weight: 500;
    display: grid;
    gap: 1rem;
    h3 {
        font-size: 18px;
        font-weight: inherit;
        font-family: inherit;
    }
    .charts {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 1rem;
    }
}
@media screen and (max-width: 480px) {
    .Container {
        .charts {
            grid-template-columns: 1fr;
        }
    }
}
