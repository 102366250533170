.Container {
    display: grid;
    gap: 1rem;
    .profile {
        // box-shadow: 0 30px 0 rgba(82, 63, 105, 0.05);
        background-color: #fff;
        padding: 1rem;
        display: grid;
        gap: 1rem;
        header {
            display: flex;
            gap: 1rem;

            img {
                width: 7rem;
            }
            .profile_details {
                display: grid;
                gap: 0.2rem;
                align-content: baseline;
                p {
                    color: gray;
                }
                h3 {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    color: #8950fc;
                    text-decoration: underline;
                    text-transform: capitalize;
                    cursor: pointer;
                }
                div {
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    button {
                        background-color: inherit;
                        border: none;
                        color: red;
                        margin-top: 0.3rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .enrolledCourses {
        display: grid;
        gap: 1rem;
    }
    .courses {
        background-color: #fff;
        padding: 1rem;

        & > h3 {
            color: #3f4254;
            font-family: poppins "open sans";
            font-size: 19.5px;
        }
        .noCourseFound {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-height: 15rem;
            padding: 1rem 0;
            height: 100%;
            img {
                width: 100%;
                max-width: 20rem;
            }
            p {
                width: 100%;
                max-width: 25rem;
                text-align: center;
            }
            button {
                text-decoration: underline;
                border: none;
                color: #8950fc;
                background-color: inherit;
                display: inline-block;
                cursor: pointer;
            }
        }
        .course-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            margin-top: 1.5rem;

            gap: 1rem;
            .course {
                display: grid;
                gap: 0.3rem;
                align-content: baseline;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    scale: 1.05;
                }
            }
            img {
                height: 100%;
                max-height: 17rem;
                width: 100%;
            }
            h5 {
                font-size: 0.9rem;
                color: #b5b5c3;
            }
            h3 {
                font-size: 14px;
                color: #8950fc;
            }
        }
    }
    .Document-container {
        background-color: white;
        padding: 1rem;
        display: grid;
        gap: 1rem;
        header {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            h3 {
                color: #3f4254;
                font-family: poppins "open sans";
                font-size: 19.5px;
            }
            button {
                color: #8950fc;
                background-color: inherit;
                border: none;
                font-size: 1.1rem;
                display: flex;
                gap: 0.2rem;
                cursor: pointer;
            }
        }
        .tabs {
            display: flex;
            gap: 0rem;
            align-items: center;
            width: fit-content;
            button {
                padding: 0.5rem 0.7rem;
                cursor: pointer;
                border: none;
                border-bottom: 2px solid #ccc;
                background-color: inherit;
            }
            .Active {
                border-bottom: 2px solid #8950fc;
                color: #8950fc;
            }
        }
        .noMedia {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img {
                max-width: 100%;
                height: 20rem;
            }
            button {
                background-color: #4b4780;
                border: transparent;
                padding: 0.5rem;
                color: white;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        .uploads {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
            gap: 1.5rem;
            margin-top: 1rem;
            .upload {
                width: 100%;
                display: grid;
                justify-items: left;
                gap: 0.6rem;
                max-width: 220px;
                div {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    button {
                        border: none;
                        background-color: inherit;
                        font-size: 1rem;
                        color: red;
                        cursor: pointer;
                    }
                }
                img {
                    max-height: 15rem;
                    width: 100%;
                    cursor: pointer;
                }
                p {
                    font-size: 0.9rem;
                    span {
                        color: #bcb8b8;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .Container {
        .course-container {
            grid-template-columns: 1fr !important;
        }
    }
}
