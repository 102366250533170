.container {
    display: grid;
    gap: 2.5rem;
    background: url("../../Assets/Parent/mainBg.jpg");
    min-height: 100vh;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    align-content: baseline;
}
.left {
    width: 85%;
    align-items: center;
    justify-self: center;
    margin-top: 1rem;
}

@media screen and (max-width: 900px) {
    .left {
        width: 94%;
    }
}
