* {
    margin: 0;
    padding: 0;
}
.swal2-title {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
}
.swal2-icon {
    margin: 1.6rem auto 0.6em;
}
.swal2-container .swal2-popup {
    padding: 0 0 0.5rem;
}
.swal-title {
    margin: 0px;
    font-size: 18px;
}
a,
.light-button,
.dark-button,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Quicksand";
}
ul li {
    text-decoration: none;
    list-style-type: none;
}
.light-button,
.dark-button {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.light-button {
    background: #faf4f8;
    border: 2px solid #eca400;
    color: black;
}
.light-button:hover {
    background-color: #eca400;
    color: white;
}
.dark-button {
    color: white;
    background: #d341fb;
    border: 2px solid #d341fb;
}
.dark-button:hover {
    background-color: #6e078a;
    border: 2px solid #6e078a;
}
h5 {
    font-size: 18px;
    line-height: 22px;
}
a {
    text-decoration: none;
}

.alice-carousel__stage-item {
    width: 100% !important;
    max-width: 18rem;
    padding: 0.8rem !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.swal-text {
    text-align: center;
}
.event-tooltip {
    position: absolute;

    z-index: 9999;
    display: block;

    max-width: 300px; /* Adjust the tooltip width as needed */
}
.border-red-600 {
    border: 1px solid red !important;
}
.ReactFlagsSelect-module_label {
    color: #ff0000 !important; /* Change this to your desired color */
}

/* Customize pagination styles */
.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin-right: 10px;
}

.pagination li a {
    display: inline-block;
    padding: 4px 8px;
    border: 1px solid #8950fc;
    // border: 1px solid #1e87db;
    color: #8950fc;
    // color: #1e87db;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
}

.pagination li a:hover {
    // background-color: #1e87db;
    background-color: #8950fc;
    color: #fff;
}

.pagination .active a {
    background-color: #8950fc;
    // background-color: #1e87db;
    color: #fff;
    font-weight: bold;
}

/* Styling previous and next buttons */
.pagination .previous a,
.pagination .next a {
    background-color: #8950fc;
    // background-color: #1e87db;
    border: 1px solid #8950fc;
    // border: 1px solid #1e87db;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

/* Pagination separator style (e.g., "..." between pages) */
.pagination .break a {
    border: none;
    color: #8950fc;
    // color: #1e87db;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
// ------swal

.swal-title {
    margin: 0px;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
}
.swal-text {
    background-color: #fefae3;
    padding: 17px;
    border: 1px solid #f0e1a1;
    display: block;
    font-family: "Montserrat", sans-serif;
    margin: 10px 22px;
    line-height: 1.2rem;
    text-align: center;
    color: #61534e;
}
.swal-icon {
    margin: 5px auto;
}
.cui-statusbar,
.WACStatusBarContainer,
#MenuSectionOptions.Open,
.UISmallFont {
    display: none !important;
}
.book-demo-right {
    position: fixed;
    right: -10px;
    z-index: 10;
    top: 50%; /* Adjust the top position as needed */
    transform: rotate(90deg);
    transform-origin: top right; /* Set the rotation point to the top right corner */
    border-radius: 50px;
    padding: 0.5rem 0.4rem 0.3rem 1rem;
    background-color: white;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid rgba(211, 65, 251, 0.5);
    // box-shadow: -27px -33px 125px -29px rgba(211, 65, 251, 0.91);
    // -webkit-box-shadow: -27px -33px 125px -29px rgba(211, 65, 251, 0.91);
    // -moz-box-shadow: -27px -33px 125px -29px rgba(211, 65, 251, 0.91);
    // box-shadow: -27px -33px 125px -29px rgba(211, 65, 251, 0.91);
}

.book-demo-right button {
    font-weight: 600;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    border: none;
    border-radius: 30%;
    font-family: "quicksand";
    letter-spacing: 0.01rem;
    gap: 0.6rem;
    font-weight: bolder;
    width: 100%;
    width: fit-content;
    padding: 0.3rem;
    font-size: 0.95rem;
    span {
        color: #d341fb;
    }
    .icon {
        background-color: #d341fb;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        border-radius: 50%;
        color: white;
        display: flex;
    }
}
