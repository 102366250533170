.Tab-Content {
    background-color: #eceef7;

    border-radius: 10px;
    min-height: 5rem;
    height: 100%;
    border-top-left-radius: 0;
    max-width: 1800px;
    padding: 2rem;
}

@media screen and (max-width: 762px) {
    .Tab-Content {
        padding: 0.5rem;
    }
}
