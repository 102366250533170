.Transaction_Container {
    background-color: white;
    padding: 1.5rem;
    display: grid;
    gap: 2rem;
    .wallet_Amount {
        display: flex;
        gap: 1rem;
        color: white;
        width: 90%;
        height: 100%;
        max-width: 18rem;
        align-items: center;
        padding: 0 2rem;
        background-color: rgb(109 7 107);
        height: 8rem;
        border-radius: 10px;
        box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
        div {
            display: grid;
            gap: 1rem;
        }
        img {
            width: 100%;
            max-width: 5rem;
        }
        button {
            background-color: #d34fba;
            border: transparent;
            border-radius: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 0.3rem;
            color: white;
            text-transform: uppercase;
            justify-content: center;
            font-weight: 550;
            padding: 0.2rem 0.5rem;

            img {
                width: 2rem;
            }
        }
    }
    .header {
        display: grid;
        gap: 01rem;
        position: relative;
        .filter {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            align-items: center;
            > input {
                height: 2.7rem;
                border-radius: 10px;
                border: 1px solid #ccc;
                width: 100%;
                text-transform: uppercase;
                max-width: 150px;
                text-indent: 0.2rem;
                outline: none;
            }
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background-repeat: no-repeat;
            width: 25px;
            height: 25px;
            border-width: thin;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 5px;
            filter: invert(0.8) sepia(100%) saturate(10000%) hue-rotate(240deg);
        }
        input::-webkit-datetime-edit-fields-wrapper {
            position: relative;
            left: 10%;
        }
        input::-webkit-datetime-edit {
            position: relative;
            left: 10%;
        }
        .course_search {
            width: 100%;
            position: relative;
            flex: 1;
            .search_icon {
                position: absolute;
                color: violet;
                top: 10px;
                left: 10px;
                font-size: 1.4rem;
            }
            input {
                height: 2.7rem;
                width: 100%;
                max-width: 350px;
                border-radius: 10px;
                text-indent: 2.5rem;
                border: 1px solid #ccc;
                outline: none;
            }
        }
    }
    .Transactions {
        display: grid;
        gap: 0.6rem;
        width: 100%;
        overflow: auto;
        .No_Transactions {
            display: grid;
            gap: 0.5rem;
            align-items: center;
            justify-items: center;
            align-content: baseline;
            img {
                width: 100%;
                max-width: 350px;
            }
            P {
                font-size: 1.2rem;
                font-family: system-ui, -apple-system, BlinkMacSystemFont,
                    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
            }
        }
        table {
            border-collapse: collapse;
            table-layout: auto;
            /* width: 100%; */
            min-width: 700px;
            text-align: left;
            border: 1px solid #ccc;
        }
        thead {
            color: white;

            border-bottom: 1px solid #ccc;
            th {
                font-family: "Montserrat", sans-serif;
                font-size: 13px;
                font-weight: 550;
                color: black;
                padding: 1rem 0rem;
                /* letter-spacing: 0.05rem; */
                text-transform: uppercase;
                vertical-align: middle;
                text-align: center;
            }
        }
        tbody tr {
            text-align: center;
            cursor: pointer;
            color: #777;
            td {
                border-bottom: 1px solid #ccc;
                font-family: "Montserrat", sans-serif;
                font-size: 15px;
                padding: 1rem 0rem;
                color: inherit;
                cursor: pointer;
                text-transform: capitalize;
            }
            .pending,
            .reject {
                background-color: #fde8e8;
                color: #f05252;
                border-radius: 10px;
                font-size: 0.8rem;
                font-weight: 700;
                padding: 0.5rem;
                display: inline-flex;
                width: fit-content;
                gap: 0rem;
                align-items: center;
            }
            .Approved {
                background-color: #def7ec;
                color: #0e9f6e;
                border-radius: 10px;
                font-size: 0.8rem;
                display: inline-flex;
                width: fit-content;
                gap: 0rem;
                align-items: center;
                font-weight: 700;
                padding: 0.5rem;
            }
        }
    }
}

@media screen and (max-width: 470px) {
    .Transaction_Container {
        padding: 0.5rem;
        .filter {
            display: grid !important;
            input {
                max-width: 100% !important;
            }
            .course_search {
                grid-row: 1/2 !important;
            }
        }
    }
}
