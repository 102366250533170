.modal {
    // display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    // padding: 15px;
    width: 480px;
    overflow: auto;
    border-radius: 5px;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 90%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
.show {
    max-height: 300px !important; /* or any value that suits your content height */
    opacity: 1 !important;
}
.Container {
    display: grid;
    gap: 0.5rem;
    height: 100%;
    max-height: 40rem;
    padding: 1rem;
    font-family: "Poppins", sans-serif;
    .header_wrapper {
        border-bottom: 1px solid rgba(103, 82, 164, 0.3);
        &:last-child {
            border: none;
        }
        button {
            border: none;
            display: flex;
            align-items: center;
            gap: 0.3rem;
            padding: 0.5rem;
            background-color: inherit;
            font-family: inherit;
            color: #6398f8;
            flex: 1;
            justify-content: flex-end;
            font-size: 15px;
            font-style: normal;
            font-weight: 450;
            cursor: pointer;
            line-height: normal;
            letter-spacing: 0.42px;
        }
    }
    header {
        display: flex;
        gap: 0.5rem;
        width: 100%;

        align-items: baseline;
        font-family: "Poppins", sans-serif;
        & > p {
            background-color: rgba(211, 79, 186, 0.45);
            color: #6752a4;
            font-family: inherit;
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
        .Content {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            align-content: baseline;
            font-family: inherit;
            h3 {
                color: #6752a4;
                line-height: 1rem;
                font-size: 19px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0.42px;
                font-family: inherit;
            }
            h4 {
                color: #d34fba;
                font-family: inherit;
                font-weight: 500;
            }
            p:nth-child(3) {
                color: #6752a4;
                font-family: inherit;
                font-weight: 400;
                font-size: 14px;
            }
            p:nth-child(4) {
                display: flex;
                align-items: center;
                gap: 0.4rem;
                font-size: 0.9rem;
                span {
                    font-size: 0.9rem;
                }
                span:nth-child(2) {
                    color: #6752a4;
                    font-family: inherit;
                }
                span:nth-child(3) {
                    color: gray;
                    font-family: inherit;
                }
            }
        }
    }
    .video-download {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .Action-btns {
        display: grid;
        button {
            border: none;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.8rem 0.5rem;
            background-color: inherit;
            border-bottom: 1px solid #ccc;
            font-weight: 550;
            font-family: inherit;
            cursor: pointer;
            &:last-child {
                border: none;
            }
            .icon {
                color: white;
                background-color: #d34fba;
                width: 2.3rem;
                height: 2.3rem;
                display: grid;
                align-items: center;
                justify-items: center;
                border-radius: 50%;
            }
            &:hover {
                background-color: #ccc;
            }
        }

        .hide {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        }
    }
    .report-card {
        display: grid;
        gap: 0.5rem;
        // .down-icon {
        //     // rotate: 180deg;
        // }
        button {
            color: white;
            background-color: #6853a1;
            border: none;
            cursor: pointer;
            font-size: 0.7rem;
            padding: 0.4rem;
            font-family: inherit;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            border-radius: 10px;
        }

        .report-content {
            background-color: #f5f5f5;
            margin-bottom: 1rem;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

            div:nth-child(even) {
                background-color: #ebebeb;
            }
            div {
                padding: 0.3rem 1rem;
                display: grid;
                grid-template-columns: 25% 5% 1fr;
                align-items: center;
                justify-content: space-between;
                h3 {
                    color: #6752a4;
                    font-family: inherit;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.48px;
                }
                p {
                    color: rgba(63, 64, 65, 0.45);
                    font-family: Quicksand;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 21px; /* 21px */
                    letter-spacing: 0.42px;
                    // text-align: justify;
                }
                img {
                    width: 1rem;
                }
            }
        }
    }
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
