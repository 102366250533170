.Container {
    background-color: white;
    border-radius: 10px;
    min-height: 5rem;
    border-top-left-radius: 0;
    max-width: 1800px;
    padding: 02rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media screen and (max-width: 1090px) {
        grid-template-columns: 1fr;
    }
    .header {
        display: flex;
        flex-wrap: wrap;
        position: sticky;
        top: 30px;
        align-self: flex-start;
        gap: 2rem;
        @media screen and (max-width: 1090px) {
            position: static;
        }
        > img {
            width: 100%;
            // max-width: 25rem;
        }
        .course-details {
            margin-top: 0.3rem;
            display: grid;
            gap: 0.4rem;
            align-content: baseline;
            font-family: "Poppins", sans-serif;
            h2 {
                font-size: 1.2rem;
                font-weight: 540;
                font-family: inherit;
            }
            p {
                display: flex;
                font-weight: 500;
                align-items: center;
                color: rgba(103, 82, 164, 0.45);
                font-family: inherit;
                gap: 0.5rem;
                font-size: 0.8rem;
                img {
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                }
            }
            .about {
                margin-top: 1.5rem;
                display: flex;
                gap: 0.8rem;
                flex-wrap: wrap;
                align-items: center;
                div {
                    display: flex;
                    gap: 0.7rem;
                    align-items: center;
                    padding: 0.5rem;
                    border-radius: 10px;
                    border: 1.5px solid rgba(25, 25, 25, 0.1);
                    h4 {
                        font-weight: 550;
                        font-size: 0.8rem;
                        font-family: inherit;
                    }
                }
            }
            .Contact {
                margin-top: 0.5rem;
                display: grid;
                gap: 1rem;
                h3 {
                    font-family: inherit;
                    font-weight: 500;
                }
                div {
                    display: flex;
                    gap: 0.5rem;
                    a {
                        color: white;
                        font-size: 0.8rem;
                        padding: 0.5rem;
                        border-radius: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .topic {
        // min-width: 600px;
        table {
            overflow: scroll;
            border-collapse: collapse;
            width: 100%;
            text-align: left;
            thead {
                tr {
                    border-bottom: 1px solid #ebedf3;
                    border-top: 1px solid #ebedf3;

                    background-color: #f3f6f9;
                    th {
                        text-align: center;
                        padding: 1rem;
                        text-transform: uppercase;

                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        letter-spacing: 1px;
                        // font-weight: 500;
                        color: #3f4254;
                        @media screen and (max-width: 1090px) {
                            text-align: left;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        text-align: center;
                        padding: 1rem;
                        color: #3f4254;
                        font-size: 15px;

                        font-family: "Poppins", sans-serif;
                        font-weight: 550;
                        vertical-align: top;
                        p {
                            display: inline-flex;
                            align-items: center;
                            gap: 0.3rem;
                        }
                        @media screen and (max-width: 1090px) {
                            text-align: left;
                        }
                        word-wrap: break-word;
                        .video-icon {
                            display: grid;
                            align-items: center;
                            justify-items: center;
                            width: fit-content;
                            cursor: pointer;
                            padding: 1rem;
                            background-color: #eee5ff;
                        }
                        .link-upload {
                            background-color: #8950fc;
                            color: white;
                            border: none;
                            padding: 0.5rem;
                            border-radius: 5px;
                        }
                        span {
                            color: #9797ae;
                            font-size: 0.8rem;
                        }
                        .Activities {
                            display: flex;
                            justify-content: center;
                            gap: 1rem;
                            button {
                                .activity-icon {
                                    background-color: #d34fba;
                                    height: 2rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 2rem;
                                    color: white;
                                    font-size: 1.2rem;

                                    border-radius: 50%;
                                }
                                display: grid;
                                gap: 0.3rem;
                                align-items: center;
                                justify-items: center;
                                background-color: inherit;
                                border: none;
                            }
                        }
                        .Action-btns {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 0.2rem;
                            button {
                                color: #6398f8;
                                border: none;
                                padding: 0.5rem;
                                font-size: 1.5rem;
                                background-color: inherit;
                            }
                        }
                        .reportClass {
                            color: #8950fc;
                            text-decoration: underline;
                            cursor: pointer;
                            border: none;
                            background-color: inherit;
                            &:disabled {
                                color: gray !important;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .topic table th:nth-child(2),
    .topic table td:nth-child(2) {
        display: none !important;
    }
}
