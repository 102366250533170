.modal {
    // display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    // padding: 15px;
    width: 100%;
    max-width: 800px;

    overflow: auto;
    border-radius: 5px;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;

    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 90%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    /* Media queries for responsive adjustments */
}
.Container {
    display: grid;
    gap: 0.5rem;
    height: 100%;
    max-height: 40rem;
    padding: 1rem;
    font-family: "Poppins", sans-serif;
    header {
        border-bottom: 1px solid #ccc;
        padding: 0.5rem 0;
        position: relative;
        h3 {
            text-align: center;
        }
        button {
            position: absolute;
            right: 0;
            top: 0;
            background-color: inherit;
            font-size: 1.5rem;
            cursor: pointer;
            border: none;
        }
    }
    form {
        display: grid;
        gap: 01rem 2rem;
        align-self: baseline;
        margin-top: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        .form_control {
            display: grid;
            gap: 0.5rem;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
            label {
                font-size: 1rem;
                font-family: inherit;
                font-weight: 400;
                color: #3f4254;
            }
            span {
                color: red;
                font-size: 0.7rem;
            }
            input,
            select {
                width: 100%;
                height: 2.5rem;
                border: 1px solid #c4c7d3;
                text-indent: 0.5rem;
                outline: none;
            }
        }
    }
}
.profile-handler {
    display: grid;
    justify-items: center;
    border: 1px dashed #ccc;
    padding: 1rem 0;
    grid-column: 1/-1;
    cursor: pointer;
}
.image {
    width: 10rem;
    height: 10rem;
    cursor: pointer;
}
.Action-btns {
    grid-column: 1/-1;
    justify-self: center;
    display: flex;
    gap: 0.5rem;
    button {
        font-size: 1rem;
        background-color: #d34fba;
        padding: 0.5rem 1rem;
        color: white;
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid #ccc;
    }
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
    cursor: pointer;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2.3rem;
}
.add-item h5 {
    font-size: 1rem;
    margin: 0;
    padding: 0;

    font-family: "Source Sans Pro", sans-serif;
}
.add-item p {
    /* color: #9e9e9e; */
    color: grey;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    font-family: "Roboto", sans-serif;
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
