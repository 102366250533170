.Container {
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    min-height: 86vh;
    height: 100%;
    background-color: #ebf2fa;
}
.Navigator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);
}
.Navigator button {
    min-height: 46px;
    padding: 12px 16px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
        segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    background-color: white;
    cursor: pointer;
    color: #22527b;
}
.Navigator button:hover {
    border-color: #dee2e6 #dee2e6 #f9f9f9;
}
.Navigator button span {
    margin-left: 4px;
    background-color: #777;
    color: #fff;
    font-weight: 500;
    border-radius: 10rem;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.active {
    border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
    background-color: #f9f9f9 !important;
}
.wrapper {
    padding: 15px 15px 40px;
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    align-content: baseline;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    width: 95%;

    & > h3:first-child {
        border-bottom: 1px solid #ddd;
        padding: 0 0 5px;
        margin: 0 0 15px;
        font-size: 1.3rem;
        display: block;
        font-weight: 500;
        line-height: 1.2;
        font-family: inherit;
        color: #212529;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        text-align: left;
    }
    form {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        button {
            color: white;
            border: 1px solid #0077b6;
            background-color: #0077b6;
            width: fit-content;
            align-self: flex-end;
            padding: 0.6rem 1rem;
            border-radius: 10px;
            cursor: pointer;
        }
    }
    .form-control {
        max-width: 250px;
        width: 95%;
        position: relative;
        display: grid;
        gap: 0.5rem;
    }
    .form-control input,
    .form-control select,
    .select {
        border: 1px solid #ccc;
        height: 2.3rem;
        font-size: 1rem;
        text-indent: 0.5rem;
        text-transform: uppercase;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
    }
    .cancel {
        color: white;
        border: 1px solid #0077b6;
        background-color: #0077b6;
        width: fit-content;
        align-self: flex-end;
        padding: 0.6rem 1rem;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 1rem;
        justify-self: center;
        &:disabled {
            background-color: lightgray;
            cursor: not-allowed;
            color: black;
            border-color: transparent;
        }
    }
}
