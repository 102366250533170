.Container {
    display: grid;
    gap: 1rem;
    & > h3 {
        font-size: 1.3rem;
        font-family: "poppins" sans-serif;
        font-weight: 650;
    }
    header {
        position: relative;
        input {
            width: 95%;
            max-width: 35rem;
            height: 2.6rem;
            border: 1px solid #ddd;
            outline: none;
            font-size: 1rem;
            text-indent: 1.9rem;
        }
    }

    .search-icon {
        position: absolute;
        top: 10px;
        left: 5px;
        font-size: 19px;
    }
}

.faq-container,
.faq-questions {
    display: grid;
    gap: 0.5rem;
}
.faq-questions {
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: white;
}
.faq-questions div {
    display: flex;
    gap: 0.5rem;
    user-select: none;
    align-items: center;
    cursor: pointer;

    h3 {
        font-size: 1rem;
        text-transform: lowercase;
    }
}
