.TopNav_Container {
    position: absolute;
    top: 40px;
    right: 0;
    border-color: red;
    display: grid;
    gap: 1.5rem;
    width: 17rem;
    // animation: animation-dropdown-menu-fade-in 0.3s ease 1,
    //     animation-dropdown-menu-move-up 0.3s ease-out 1;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    font-size: 1rem;
    color: #3f4254;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.42rem;
    padding: 1rem;
    .header {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        h3 {
            color: #181c32;
            font-family: "Poppins", sans-serif;
            font-weight: 550;
            font-size: 0.95rem;
        }
        img {
            width: 3rem;
        }
    }
    ul {
        display: grid;
        gap: 0.5rem;
        text-decoration: none;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;
        li {
            text-decoration: none;
            button {
                font-size: 0.98rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 0.4rem;
                color: #3f4254;
                font-family: "Poppins", sans-serif;
                border: none;
                background-color: inherit;
                &:hover {
                    color: #8950fc;
                }
            }
        }
    }
    & > button {
        color: #8950fc;
        border-color: transparent;
        padding: 0.5rem;
        border-radius: 5px;
        outline: none;
        font-size: 0.9rem;
        cursor: pointer;
        width: fit-content;
        background-color: #eee5ff;
        &:hover {
            color: white;
            background-color: #8950fc;
        }
    }
}
.logout-popup {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    font-size: 1rem;
    text-align: center;
}
.popup-actions {
    display: flex;
    gap: 0.3rem;
    justify-self: right;
    justify-content: flex-end;
    margin: 0.4rem 0.5rem 0.1rem 0.5rem;
}
@keyframes animation-dropdown-menu-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
