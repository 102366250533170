.modal {
    /* Default styles */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 650px;
    overflow: auto;
    animation: bounce 0.5s ease;
    // animation-fill-mode: forwards;
    /* Media queries for responsive adjustments */
    @media (max-width: 768px) {
        width: 80%;
    }
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
.thankyou {
    width: 700px;
    padding: 0;
    outline: none;
    @media screen and (max-width: 786px) {
        width: 90%;
    }
    // top: 40%;
}
.thankyou-header {
    outline: none;
    background-image: url("../../../../Assets/thankyou_bg.svg");
    background-position: center;
    background-size: cover;
    height: 250px;
    display: grid;
    justify-items: center;
    align-items: center;
    align-content: baseline;
    padding: 2rem;
    gap: 0.5rem;
    position: relative;
    button {
        position: absolute;
        right: 10px;
        top: 10px;
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 1.5rem;
        cursor: pointer;
    }
    img {
        max-width: 150px;
        width: 90%;
    }
    h3 {
        color: #f71735;
        font-size: 30px;
    }
    p {
        font-weight: 550;
        font-size: 20px;
        text-align: center;
    }
}
.body {
    background: #fffaf4;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 1rem;
    gap: 2rem;
    h3 {
        font-size: 20px;
        text-align: center;
    }

    .Links {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        a {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #d34fba;
            border: 1px solid #d34fba;
            padding: 0.5rem;
            font-size: 1rem;
            &:hover {
                color: white;
                background-color: #d34fba;
            }
        }
    }
}
.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.form-wrapper {
    display: grid;
    gap: 2rem;
    align-content: baseline;
    justify-items: center;
    align-items: center;
    // padding: 1rem;
    // padding-top: 2rem;
    font-family: "Poppins", sans-serif;

    min-height: 400px;
    background: url("../../../../Assets/Parent/form_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        text-align: center;
        h2 {
            font-family: inherit;
            color: black;
            font-size: 1.5rem;
        }
        p {
            font-family: inherit;
            color: #ada9a9;
            font-size: 1rem;
            letter-spacing: 0.1rem;
            span {
                font-weight: bold;
                color: black;
                border-bottom: 5px solid yellow;
            }
        }
    }
    .select-btn {
        color: black !important;
        span {
            font-size: 0.8rem;
        }
    }
    form {
        display: grid;
        gap: 1rem;
        justify-items: center;
        align-self: center;
        width: 80%;
        .form-control {
            display: grid;
            gap: 0.3rem;
            width: 80%;
            position: relative;
            .input-number {
                position: absolute;
                top: 5px;
                button {
                    border: none;
                    padding: 0;
                }
                select {
                    border: none;
                }
                input {
                    text-indent: 0.5rem;
                }
            }
            .icon {
                color: #1e87db;
                position: absolute;
                top: 7px;
            }
            & > span {
                color: red;
                font-size: 0.8rem;
            }
            // select {
            //     padding-left: 0.2rem;
            // }
            select,
            input {
                cursor: pointer;
                border: none;
                height: 2.2rem;
                border-bottom: 2px solid lightgray;
                color: black;
                text-indent: 2rem;
                font-family: inherit;
                font-size: 1rem;
                outline: none;
                appearance: none;
            }
            select option {
                text-indent: 0.5rem;
            }
        }
    }
    .contact {
        display: grid;
        gap: 0.5rem;
        justify-items: center;
        P {
            font-family: inherit;
            font-size: 1rem;
            color: #ada9a9;
        }
    }
}
.form-wrapper > button {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 1.5rem;
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.close {
    width: fit-content;
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 0 1rem;
    height: 2.5rem;
}
.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    button {
        background-color: #1e87db;
        color: white;
        text-align: center;
        border-radius: 10px;
        border: 1px solid;
        cursor: pointer;
        padding: 0 1rem;
        height: 2.5rem;
    }
}
.submit {
    background-color: rgba(211, 65, 251, 1);
    color: white;
    text-align: center;
    padding: 1rem 0;
    border-radius: 10px;
    border: 1px solid;
    cursor: pointer;
}
.close {
    cursor: pointer;
    border: 1px solid rgba(250, 199, 89, 1);
    background-color: lightgray;
    border-radius: 5px;
    color: black;
    padding: 1rem 0;
}
@media screen and (max-width: 500px) {
    .form-wrapper {
        form {
            input,
            select {
                width: 100% !important;
            }
        }
    }
}
@media screen and (max-width: 786px) {
    .thankyou-header {
        height: unset;
        // padding: 0.5rem;
        justify-items: center;
        img {
            width: 100px;
        }
        h3 {
            font-size: large;
        }
        p {
            font-size: small;
        }
    }
    .body {
        h3 {
            font-size: large;
        }
        .Links a {
            font-size: small;
        }
    }
}
@media screen and (max-width: 800px) {
    .form-wrapper {
        padding-top: 3rem;
    }
}
@media screen and (max-width: 500px) {
    .contact {
        p {
            text-align: center;
        }
    }
}
