.modal {
    /* Default styles */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 500px;
    overflow: auto;
    animation: bounce 0.5s ease;
    animation-fill-mode: forwards;
    outline: none;
    opacity: 0;
    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 480px) {
        width: 60%;
    }
}

.overlay {
    /* Default styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;

    /* Media queries for responsive adjustments */
    @media (max-width: 480px) {
        background-color: rgba(0, 0, 0, 0.8);
    }
}
@keyframes bounce {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0;
    }
    60% {
        transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
        transform: translate(-50%, -50%) scale(0.95);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}
.Wrapper {
    padding: 0.2rem;
}
.Wrapper ul li {
    list-style-type: decimal;
    /* font-family: "Open Sans", serif; */
    padding: 0.1rem;
    font-family: "Montserrat", sans-serif;
    line-height: 1.3rem;
    font-size: 0.9rem;
    text-align: justify;
}
