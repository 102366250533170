.Course-details {
    display: grid;
    gap: 0.5rem;
    header {
        display: flex;
        align-items: center;
        justify-content: center;
        .overlay {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.3);
            left: 0;
            right: 0;
            height: 22rem;
        }
        // position: relative;
        h2 {
            position: absolute;
            top: 11rem;
            z-index: 4;
            font-size: 2.625rem;
            text-transform: uppercase;
            font-weight: 700;
            text-transform: uppercase;
            font-weight: 700;
            color: white;
        }
        .img {
            width: 100%;
            height: 22rem;
            display: flex;

            align-items: center;
            justify-content: center;
            // background-color: yellow;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // object-position: center;
            }
        }
    }
    .Content-wapper {
        display: grid;
        align-items: center;
        justify-items: center;
        padding: 2rem;
        .Content {
            width: 70%;
            display: grid;
            grid-template-columns: 65% 1fr;
            font-family: "Poppins", sans-serif;
            @media screen and (max-width: 930px) {
                grid-template-columns: 1fr;
            }
            .Content-left {
                display: grid;
                gap: 1rem;
                align-content: baseline;
                div {
                    align-content: baseline;
                    display: grid;
                    gap: 0.6rem;
                    h3 {
                        font-family: inherit;
                        font-size: 23px;
                        font-weight: 550;
                        margin: 0;
                        padding: 0;
                        @media screen and (max-width: 800px) {
                            font-size: x-large;
                        }
                    }
                    p {
                        font-family: inherit;
                        font-size: 16px;
                        color: #555555;
                    }
                }
                .faq-container {
                    display: grid;
                    gap: 0rem;
                }
                .faq-questions {
                    display: grid;
                    // gap:0.5rem;
                    // transition: all 1s ease;
                }
                .faq-questions div {
                    display: flex;
                    gap: 0.5rem;
                    background-color: rgba(0, 0, 0, 0.03);
                    align-items: center;
                    color: white;
                    padding: 0.5rem 1rem;
                    font-family: inherit;
                    text-transform: uppercase;
                    cursor: pointer;
                    user-select: none;
                }
                .faq-questions div span {
                    font-weight: 900;
                    font-size: 22px;
                }
                .faq-questions div h3 {
                    font-size: 15px !important;
                    font-family: inherit;
                    color: #555555;
                }
                .faq-questions p {
                    font-size: 16px;
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.3s ease;
                }
                .open {
                    max-height: 1000px !important;
                    margin: 0.5rem 0;
                }
                .toggle-icon {
                    transition: all 0.3s ease;
                    color: lightblue;
                }

                .toggle-icon-open {
                    transform: rotate(360deg);
                }
                .price {
                    display: grid;
                    gap: 0.5rem;
                }
                .price p {
                    display: flex;
                    align-items: center;
                }
            }
            @media screen and (max-width: 1230px) {
                width: 100%;
                padding: 1rem;
            }
        }
        @media screen and (max-width: 1230px) {
            padding: 1rem;
        }
    }
}
