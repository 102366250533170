.Container header {
    background: url("../../../Assets/about/aboutusbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 25rem;
    padding: 1rem 15rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    & > img {
        width: 100%;
        overflow: hidden;
        height: 100%;
        max-height: 20rem;
    }
}
.course_heading {
    display: grid;
    gap: 0.4rem;
    justify-self: baseline;
}
.course_heading h4 {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    background-color: #f19426;
    width: fit-content;
    padding: 0.3rem;
}
.course_heading > h3 {
    font-weight: 700;
    font-size: 40px;
}
.course_heading > p {
    font-size: 16px;
}
.teacher {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.teacher div:first-child {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.teacher div:nth-child(2) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4rem;
}
.teacher img {
    width: 3rem;
}
.course_content {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 35%;
    gap: 1rem;
    justify-items: left;
    padding: 3rem 15rem;
    background-color: white;
}
.left__side {
    display: grid;
    gap: 2rem;
    padding: 2rem 0;
    align-content: baseline;
    h3 {
        font-weight: 700;
        font-size: 24px;
    }
    p {
        font-size: 16px;
        color: #5f5f5f;
    }
    h4 {
        font-size: 18px;
    }
    ul {
        li {
            font-family: inherit;
            color: #5f5f5f;
            font-size: 18px;
            line-height: 30px;
            font-weight: 500;
        }
    }
    ul li:before {
        content: url("../../../Assets/tick.svg");
        margin-right: 0.5rem;
    }
    .user-details {
        display: flex;
        padding: 1.5rem;
        gap: 1rem;
        margin-top: 1rem;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(136, 136, 136, 0.1);

        img {
            width: 100%;
            max-width: 7rem;
        }
        div {
            display: grid;
            align-content: baseline;
            gap: 0.2rem;
            h3 {
                font-weight: 700;
                font-size: 18px;
            }
            h5,
            p {
                font-size: 15px;
                color: #5f5f5f;
            }
            p {
                margin-top: 0.4rem;
                font-size: 15px;
            }
            .social-links {
                display: flex;
                gap: 0.4rem;
                align-items: center;
                margin: 1rem 0 0 1rem;
                font-size: 1rem;
                img {
                    width: 100%;
                    max-width: 1.8rem;
                }
            }
        }
    }
    .Comments {
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(136, 136, 136, 0.1);
        margin-top: 1rem;
        display: grid;
        & > h3 {
            font-weight: 700;

            padding: 1rem 1.5rem;
            font-size: 17px;
            border-bottom: 1px solid #fff9f1;
        }
        img {
            width: 100%;
            max-width: 4rem;
            padding: 0 1rem;
        }
        .comment {
            display: flex;
            gap: 0.5rem;

            div {
                padding: 0.5rem;
            }
            h3 {
                font-weight: 400;
                font-size: 15px;
            }
            h5 {
                font-weight: 400;
                font-size: 14px;
                color: #555555;
            }
            p {
                font-size: 14px;
                color: #555555;
                padding: 0.3rem 0;
            }
        }
    }
    .leaveComment {
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(136, 136, 136, 0.1);
        & > h3 {
            font-weight: 700;

            padding: 1rem 1.5rem;
            font-size: 17px;
            border-bottom: 1px solid #fff9f1;
        }
        form {
            padding: 1rem 1.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            .form-control:nth-child(3),
            .form-control:nth-child(4) {
                grid-column: 1/-1;
            }
            .form-control {
                display: grid;
                gap: 0.3rem;
                input {
                    width: 100%;
                    height: 2.5rem;
                    background-color: #ecf0f3;
                    color: #5f5f5f;
                    text-indent: 1rem;
                    border: none;
                    outline: none;
                    font-size: 14px;
                }
                textarea {
                    text-indent: 1rem;
                    resize: none;
                    padding: 0.5rem;
                    outline: none;
                    background-color: #ecf0f3;
                    border: none;
                    font-family: inherit;
                    font-size: 16px;
                }
            }
            button {
                color: white;
                background: #d341fb;
                width: fit-content;
                border: none;
                height: 2rem;
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-items: center;
                // text-transform: uppercase;
                font-size: 0.9rem;
                cursor: pointer;
                border-radius: 4px;
            }
        }
    }
}
.right__side {
    width: 100%;
    display: grid;
    height: fit-content;
    align-content: baseline;
    background-color: white;
    justify-self: baseline;
    position: sticky;
    top: 100px;

    & > h3 {
        color: white;
        font-weight: 500;
        font-size: 16px;
        background-color: #d34fba;
        padding: 0.5rem 1rem;
    }
    .payment_details {
        display: grid;
        padding: 0.5rem;
        .detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem;
            &:nth-child(even) {
                background-color: #f9f9f9;
            }
            h3 {
                font-size: 16px;
                line-height: 27px;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 0.4rem;
            }
        }
        button {
            background: #8950fc;
            border-radius: 4px;
            padding: 0.5rem 0;
            font-weight: 600;
            color: #ffffff;
            border: none;
            cursor: pointer;
            margin-top: 0.5rem;
        }
    }
}
.paymentDetails-mobile {
    display: none;
}
.payment-details-web,
.payment-details-web {
    width: 100%;
}
.faq-container {
    display: grid;
    gap: 0.5rem;
}
.faq-questions {
    display: grid;
    // gap:0.5rem;
    // transition: all 1s ease;
}
.faq-questions div {
    display: flex;
    gap: 0.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    align-items: center;
    color: white;
    padding: 0.5rem 1rem;
    font-family: inherit;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
}
.faq-questions div span {
    font-weight: 900;
    font-size: 22px;
}
.faq-questions div h3 {
    font-size: 15px !important;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #555555;
}
.faq-questions p {
    font-size: 16px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
}
.open {
    max-height: 1000px !important;
    margin: 0.5rem 0;
}
.toggle-icon {
    transition: all 0.3s ease;
    color: lightblue;
}

.toggle-icon-open {
    transform: rotate(360deg);
}
.price {
    display: grid;
    gap: 0.5rem;
}
.price p {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 1300px) {
    .Container header,
    .course_content {
        padding: 1rem 8rem;
    }
}
@media screen and (max-width: 1100px) {
    .Container header,
    .course_content {
        gap: 2rem;
        padding: 2rem 3rem;
    }
    .course_content {
        grid-template-columns: 1fr;
    }
    .paymentDetails-mobile {
        margin-top: 1rem;
        display: block;
    }
    .payment-details-web {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .Container header {
        padding: 2rem 1rem;
        height: fit-content;
        grid-template-columns: 1fr;
        .teacher:nth-child(2) {
            flex-direction: column;
        }
        h3 {
            font-size: 4vw;
        }
        & > img {
            grid-row: 1/2;
        }
        p {
            font-size: 3vw;
        }
    }
    .course_content {
        padding: 1rem;
        gap: 1rem;
    }
}
@media screen and (max-width: 600px) {
    .left__side ul li {
        font-size: 4vw;
    }
    .left__side h3,
    .left__side h4 {
        font-size: 3.5vw !important;
    }
    .left__side p,
    .payment_details > h3,
    .payment_details h4 {
        font-size: 3.2vw !important;
    }
}
